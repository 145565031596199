import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/Layout"
import Banner from "../components/Banner"
import { PageSeo } from "../components/PageSeo"

const WhitepapersTemplate = ({ data }) => {
  const whitepapersArr = data.allMarkdownRemark.edges
  const { markdownRemark: markup } = data
  const { banner } = markup.frontmatter

  var groupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
      ;(rv[x.node.frontmatter[key]] = rv[x.node.frontmatter[key]] || []).push(x)
      return rv
    }, {})
  }
  const groupData = groupBy(whitepapersArr, "year")

  return (
    <Layout>
      <Banner data={banner} />
      <section className="WhitepapersTemplate container py-3 py-lg-5">
        {Object.keys(groupData)
          .reverse()
          .map((key, index) => {
            return (
              <div key={index}>
                <h3 className="text-primary">{key}</h3>
                <div className="row">
                  {groupData[key].length &&
                    groupData[key].map(({ node }, index) => (
                      <ItemTemplate
                        key={`whitepapersArr_${index}`}
                        item={node.frontmatter}
                      />
                    ))}
                </div>
              </div>
            )
          })}
      </section>
    </Layout>
  )
}

const ItemTemplate = ({ item }) => {
  return (
    <div className="col-12 col-lg-6 h-100">
      <div className="card mb-3 h-100">
        <div className="row g-0">
          <div className="col-md-4">
            <GatsbyImage
              image={item.image.childImageSharp.gatsbyImageData}
              alt={item.imageName}
              className="rounded-start h-100"
            />
          </div>
          <div className="col-md-8">
            <div className="card-body">
              <h5 className="card-title text-uppercase">{item.name}</h5>
              <p className="card-text">{item.description}</p>
              {item.link && (
                <a
                  href={item.link}
                  className="text-primary text-capitalize text-bold text-decoration-none"
                >
                  Read More
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

ItemTemplate.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string,
    url: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
}

export const WhitepapersQuery = graphql`
  query WhitepapersQuery($id: String!) {
    allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___sequence] }
      filter: { frontmatter: { homeSection: { eq: "whitepapers" } } }
    ) {
      edges {
        node {
          frontmatter {
            name
            description
            sequence
            year
            link
            image {
              childImageSharp {
                gatsbyImageData(quality: 100, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }

    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        name
        banner {
          heading
          description
          image {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`

export default WhitepapersTemplate
export const Head = () => (
  <PageSeo
    title="Whitepapers | INVESTMENT MANAGEMENT SOFTWARE SOLUTION PROVIDER"
    description="Thought leadership in Investment Management industry"
    keywords="CHAT BASED ROBO-ADVISORS - A GAME CHANGER, ROBO ADVISORY - BEYOND MEAN VARIANCE OPTIMIZATION, TAXATION ON INVESTMENTS IN PHILIPPINES MARKET"
  />
)
